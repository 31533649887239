angular.module("common").
    factory("_u", function($rootScope) {
        function throttle(callback, delay) {
            return _.throttle(function () {
                var answer = callback();
                if (answer) {
                    $rootScope.$safeApply();
                }
            }, delay || 200);
        }

        function debounce(callback, delay) {
            return _.debounce(function () {
                var answer = callback();
                if (answer) {
                    $rootScope.$safeApply();
                }
            }, delay || 200);
        }

        return {
            throttle: throttle,
            debounce: debounce
        }
    });